import TimeKeeper from 'Models/TimeKeeper';
import { fetchTutorialData } from 'Actions/app';
import { NumericDictionaryIteratee } from 'lodash';
import { Note, NoteType, Tie } from 'opensheetmusicdisplay';
import { UserMedia } from 'tone';
import { MIDIValInput } from '@midival/core';
import { CognitoUser } from "amazon-cognito-identity-js";
import { userAttributes } from 'Utils/Amplify';

export enum MidiEventType {
  'NOTE_ON' = 'NOTE_ON',
  'NOTE_OFF' = 'NOTE_OFF'
}

export enum MidiEventState {
  'UNSET' = 'UNSET',
  'MISSED' = 'MISSED',
  'HIT' = 'HIT',
  'OFF_TIME' = 'OFF_TIME'
}

// useful for using as key type
export type NoteTypeAsList = NoteType._1024th | NoteType._512th | NoteType._256th | NoteType._128th | 
                        NoteType._64th | NoteType._32nd | NoteType._16th | NoteType.EIGTH | NoteType.QUARTER |
                        NoteType.HALF | NoteType.WHOLE | NoteType.QUARTER | NoteType.BREVE | NoteType.UNDEFINED | 
                        NoteType.LONG | NoteType.MAXIMA;

export enum NoteColor {
  CORRECT = '#FF0000',
  INCORRECT = '#00CC00'
}

export type TickNoteEventType = CustomEvent & {
  detail: {
    name: string,
    ticks: number,
    timekeeper: TimeKeeper,
    workerState: object
  }
}

// Way over simplistic for now. E.g. There's no way this will have "NoteType" on it.
export type MusicXMLNote = {
  time: number,
  duration: number
  midi: number,
  note: string,
  noteType: NoteType
}

export type MidiEvent = {
  velocity: number,
  duration: number,
  midi: number,
  ticks: number,
  name: string,
  // TODO: Change this to eventType. "event" is confusing.
  event: MidiEventType,
  time: number,
  channel: number
}

export type AddressData = {
  city: string;
  state: string;
  zipCode: number;
  address1: string;
  address2: string;
};

export type StateData = {
}

export type HistoryData = {

}

export type UpdateProfileData = {
  emailOrPhone: string;
  name: string;
  unknown: string
};

export type SignUpData = {
  emailOrPhone: string;
  name: string;
  password: string;
  repeatPassword: string;
  unknown: string;
};

export type SignInData = {
  emailOrPhone: string;
  password: string;
  unknown: string
};

export type ResetCodeData = {
  code: string;
  unknown: string;
}


export type ChangePasswordData = {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
  unknown: string;
}

export type ResetPasswordData = {
  password: string;
  repeatPassword: string;
  unknown: string;
}

export type ForgotPasswordData = {
  emailOrPhone: string;
  unknown: string;
}

export interface LessonPageProps {
  className?: any;
};

export interface MenuIconProps {
  className?: any;
};

export interface CardProps {
  className?: any;
};

// Type isn't exported?
export type AmplifyAuthError = {
  code: string,
  message: string
}

export interface AccuracyProps {
  className?: any;
  accuracy: number;
  accuracyChange: number;
};

export interface TempoProps {
  className?: any;
  tempo: number;
  tempoChange: number;
};

export interface AppDataProps {
  levelSelect: number;
}

export interface IClientData {
  userId: number;
  playMode: string;
  lastScreen: string;
  speakerMode: string;
}

export interface FetchClientDataSuccessPayload {
  data: IClientData;
}

export interface FetchClientDataErrorPayload {
  error: string;
}

export interface FetchClientDataRequestPayload {
  jwt: string;
}

export interface setLessonPlayingPayload {
  lessonPlaying: boolean;
}

export interface SetLevelSelectPayload {
  levelSelect: number;
}

export interface SetUnitSelectPayload {
  unitSelect: number;
  authToken: string | undefined
}

export interface SetTempoPayload {
  tempo: number;
}


export interface SetHighestLevelCompletedPayload {
  highestLevelCompleted: number;
}


export interface ITutorialData {
  template_number: number;
  title: string;
  info_text: string;
  srcs: string;
}

export interface FetchTutorialDataSuccessPayload {
  tutorialData: ITutorialData[]
}

export interface FetchTutorialDataPayload {

}

export interface AuthenticationToken {
  authToken: string;
}

export interface UserInfo {
  user: any;
}

export enum LevelStatus {
  active = 'active',
  complete = 'complete'
}

export type AugmentedCognitoUser = CognitoUser & {attributes: UserAttributes}

export type InitialFederatedUserNoAttributes = ({

})

// "signInUserSession": {
  //         "idToken": {
  //             "payload": {
  //                 ...
  //                 "name": "Steven Staley",
  //                 ...
  //                 "email": "steven.staley@museflow.ai"
  //             }
  //         },
  //         "accessToken": {
  //             "payload": {
  //               ...
  //                 "sub": "9a75c93b-f333-4a42-bc59-0a5b0ea16357",
  //         },
  //     }
export type UserAttributes = {
  email: string,
  email_verified: boolean,
  sub: string,
  'custom:is_test_user': boolean,
  'custom:user_type': string,
}

export type UserLevelData = {
  bpm: number;//120
  createdAt: string;//"2023-03-16T01:29:42.749Z"
  current_tier: number;//1
  current_tier_accuracy: number;//null
  deletedAt: number;//null
  highest_tier_complete: number;//0
  highest_tier_completed_at: string;//null
  id: number;//1
  level_completed_at: string;//null
  level: {
    level_number: number; //1
  };
  phrases_til_next_tier: number;//4
  status: LevelStatus;//"active"
  updatedAt: string;//"2023-03-16T01:29:42.749Z"
  user_id: string;//1
}

export interface UserLevelDataSuccessPayload {
  userLevelData: UserLevelData[]
}

export type LevelData = {
  level_number: number;
  // unit_number: string;
  unit: {
    unit_number: number
  };
  name: string;
  description: string;
  srcs: string;
}

export type PhraseData = {
  createdAt: string
  id: number
  s3_batch_datetimestamp: string
  s3_music_xml_id: string
  s3_music_xml_url: string
  s3_svg_timesig_url: string
  s3_svg_url: string
  tagging_version: string
  tier_number: number
  updatedAt: string
}

export type TiersByLevels = {
  level_number: number;
  tiers: number[];
}

export interface LevelDataSuccessPayload {
  levelData: LevelData[]
}

export interface UpdateCurrentULPPayload { 
  currentUserLevelProgress: Partial<UserLevelData>
  authToken: string
}


export type UnitData = {
  unit_number: number;
  name: string;
  description: string;
}

export type TutorialData = {
  template_number: number;
  title: string;
  info_text: string;
  srcs: string;
}


export enum actionTypes {
  FETCH_CLIENT_DATA_REQUEST = "FETCH_CLIENT_DATA_REQUEST",
  FETCH_CLIENT_DATA_SUCCESS = "FETCH_CLIENT_DATA_SUCCESS",
  FETCH_CLIENT_DATA_ERROR = "FETCH_CLIENT_DATA_ERROR",
  SET_LESSON_PLAYING = "SET_LESSON_PLAYING",
  SET_LEVEL_SELECT = "SET_LEVEL_SELECT",
  SET_UNIT_SELECT = "SET_UNIT_SELECT",
  SET_TEMPO = "SET_TEMPO",
  FETCH_TUTORIAL_DATA = "FETCH_TUTORIAL_DATA",
  FETCH_TUTORIAL_DATA_PROCESSING = "FETCH_TUTORIAL_PROCESSING",
  FETCH_TUTORIAL_DATA_SUCCESS = "FETCH_TUTORIAL_DATA_SUCCESS",
  MIDI_INPUT_SETUP = "MIDI_INPUT_SETUP",
  AUTHENTICATED_STARTUP = "AUTHENTICATED_STARTUP",
  FETCH_USER_LEVEL_SUCCESS = "FETCH_USER_LEVEL_SUCCESS",
  SET_HIGHEST_LEVEL_COMPLETED = "SET_HIGHEST_LEVEL_COMPLETED",
  FETCH_LEVEL_DATA_SUCCESS = "FETCH_LEVEL_DATA_SUCCESS",
  UPDATE_CURRENT_ULP = "UPDATE_CURRENT_ULP",
  UPDATE_CURRENT_ULP_SUCCESS = "UPDATE_CURRENT_ULP_SUCCESS",
  UPDATE_PHRASES_TIL_NEXT_TIER = "UPDATE_PHRASES_TIL_NEXT_TIER",
  UPDATE_CURRENT_LEVEL_TIERS = "UPDATE_CURRENT_LEVEL_TIERS",
  UPDATE_TIERS_BY_LEVELS = "UPDATE_TIERS_BY_LEVELS",
  SET_LAST_SHOWN_SURVEY = "SET_LAST_SHOWN_SURVEY",
  SET_SHOWN_INTRODUCTION = "SET_SHOWN_INTRODUCTION",
  SET_USER_DATA = "SET_USER_DATA",
  UPDATE_USER_DATA = "UPDATE_USER_DATA",
  RESET_LESSON = "RESET_LESSON",
  SET_AUDIO_ON = "SET_AUDIO_ON",
  SET_REP_METRONOME_SOUND = "SET_REP_METRONOME_SOUND",
  SET_REP_DOWNBEATS_SOUND = "SET_REP_DOWNBEATS_SOUND",
  LOAD_USER_STATE = "LOAD_USER_STATE",
  UPDATE_MAILCHIMP_INFO = "UPDATE_MAILCHIMP_INFO",
  SET_FULL_SCREEN = "SET_FULL_SCREEN",
  GET_SUBSCRIPTION_STATUS = "GET_SUBSCRIPTION_STATUS",
  SET_SUBSCRIPTION_STATUS = "SET_SUBSCRIPTION_STATUS",
  SET_PLAY_SESSION_ID = "SET_PLAY_SESSION_ID"
}

export interface FetchClientDataRequest {
  type: typeof actionTypes.FETCH_CLIENT_DATA_REQUEST;
}

export type FetchClientDataSuccess = {
  type: typeof actionTypes.FETCH_CLIENT_DATA_SUCCESS;
  payload: FetchClientDataSuccessPayload;
};

export type FetchClientDataError = {
  type: typeof actionTypes.FETCH_CLIENT_DATA_ERROR;
  payload: FetchClientDataErrorPayload;
};

export type setLessonPlaying = {
  type: typeof actionTypes.SET_LESSON_PLAYING;
  payload: setLessonPlayingPayload;
}

export type SetLevelSelect = {
  type: typeof actionTypes.SET_LEVEL_SELECT;
  payload: SetLevelSelectPayload;
}

export type SetUnitSelect = {
  type: typeof actionTypes.SET_UNIT_SELECT;
  payload: SetUnitSelectPayload;
}

export type SetTempo = {
  type: typeof actionTypes.SET_TEMPO;
  payload: SetTempoPayload;
}

export type FetchTutorialData = {
  type: typeof actionTypes.FETCH_TUTORIAL_DATA;
  payload: AuthenticationToken;
}

export type FetchTutorialDataProcessing = {
  type: typeof actionTypes.FETCH_TUTORIAL_DATA_PROCESSING;
  payload: boolean;
}

export type FetchTutorialDataSuccess = {
  type: typeof actionTypes.FETCH_TUTORIAL_DATA_SUCCESS;
  payload: FetchTutorialDataSuccessPayload;
};

export type MidiInputSetup = {
  type: typeof actionTypes.MIDI_INPUT_SETUP;
  payload: MIDIValInput
}

export type SetLastShowSurvey = {
  type: typeof actionTypes.SET_LAST_SHOWN_SURVEY;
  payload: string
}

export type SetShownIntroduction = {
  type: typeof actionTypes.SET_SHOWN_INTRODUCTION;
  payload: boolean
}

export type SetUserData = {
  type: typeof actionTypes.SET_USER_DATA;
  payload: IUserData
}

export type UpdateUserData = {
  type: typeof actionTypes.UPDATE_USER_DATA;
  payload: IUpdateUserData
}

export type AuthenticatedStartup = {
  type: typeof actionTypes.AUTHENTICATED_STARTUP;
  payload: AuthenticationToken & UserInfo
}

export type FetchUserLevelSuccess = {
  type: typeof actionTypes.FETCH_USER_LEVEL_SUCCESS;
  payload: UserLevelDataSuccessPayload
}

export type SetHighestLevelCompleted = {
  type: typeof actionTypes.SET_HIGHEST_LEVEL_COMPLETED;
  payload: SetHighestLevelCompletedPayload
}


export type FetchLevelDataSuccess = {
  type: typeof actionTypes.FETCH_LEVEL_DATA_SUCCESS;
  payload: LevelDataSuccessPayload
}

export type UpdateCurrentULP = {
  type: typeof actionTypes.UPDATE_CURRENT_ULP;
  payload: UpdateCurrentULPPayload & UserInfo
}

export type UpdateCurrentULPSuccess = {
  type: typeof actionTypes.UPDATE_CURRENT_ULP_SUCCESS;
  payload: UserLevelData
}

export type UpdatePhrasesTilNextTier = {
  type: typeof actionTypes.UPDATE_PHRASES_TIL_NEXT_TIER;
  payload: number
}

export type UpdateCurrentLevelTiers = {
  type: typeof actionTypes.UPDATE_CURRENT_LEVEL_TIERS;
  payload: number
}

export type UpdateTiersByLevels = {
  type: typeof actionTypes.UPDATE_TIERS_BY_LEVELS;
  payload: TiersByLevels
}

export type LoadUserState = {
  type: typeof actionTypes.LOAD_USER_STATE;
  payload: AppState
}

export type setLastShowSurvey = {
  type: typeof actionTypes.SET_LAST_SHOWN_SURVEY;
  payload: TiersByLevels
}

export type setShownIntroduction = {
  type: typeof actionTypes.SET_SHOWN_INTRODUCTION;
  payload: TiersByLevels
}

export type setUserData = {
  type: typeof actionTypes.SET_USER_DATA;
  payload: IUserData
}

export type ResetLesson = {
  type: typeof actionTypes.RESET_LESSON;
  payload: null
}

export type SetAudioOn = {
  type: typeof actionTypes.SET_AUDIO_ON;
  payload: {
    audioOn: boolean
  } & AuthenticationToken
}

export type SetRepMetronomeSound = {
  type: typeof actionTypes.SET_REP_METRONOME_SOUND;
  payload: {
    repMetronomeSound: boolean
  } & AuthenticationToken
}

export type SetRepDownbeatsSound = {
  type: typeof actionTypes.SET_REP_DOWNBEATS_SOUND;
  payload: {
    repDownbeatsSound: boolean
  } & AuthenticationToken
}

export type SetPlaySessionId = {
  type: typeof actionTypes.SET_PLAY_SESSION_ID;
  payload: string
}


export enum UserType {
  user = 'user',
}

export interface AppState {
  errors: string[],
  loading: boolean,
  data: IClientData[],
  unitSelect:number,
  levelSelect: number,
  lessonPlaying: boolean,
  tempo: number;
  tutorialData: ITutorialData[],
  tutorialDataProcessing: boolean,
  userLevelData: UserLevelData[],
  highestLevelCompleted: number;
  levelData: LevelData[],
  currentUserLevelData: UserLevelData | null,
  phrasesTilNextTier: number,
  currentLevelTiers: number,
  tiersByLevels: TiersByLevels[],
  midiInput: MIDIValInput | null,
  lastShownSurvey: string | null,
  isMidiConnected: boolean | null,
  hasShownIntroduction: boolean | null,
  userData: IUserData | null,
  resetLessonUuid: string | null,
  isFullScreen: boolean,
  playSessionId: string | null
}

export interface IUserData {
  stripe_customer_id: string;
  subscription_status: string;
  coupon_code: string | null;
  audio_on: boolean;
  rep_metronome_sound: boolean;
  rep_downbeats_sound: boolean;
  user_id?: string;
  last_shown_survey?: Date | null;
  name?: string | null;
  email?: string | null;
  phone_number?: string | null;
  accepts_mailing_list?: boolean | null;
  game_tutorial_shown?: Date | null;
  user_type?: UserType | null
  is_test_user?: boolean | null
}

export interface IUpdateUserData {
  stripe_customer_id?: string | null;
  subscription_status?: string | null;
  coupon_code?: string | null;
  audio_on?: boolean | null;
  rep_metronome_sound?: boolean | null;
  rep_downbeats_sound?: boolean | null;
  user_id?: string | null;
  last_shown_survey?: Date | null;
}

export interface LessonState {
  userLevelData: UserLevelData
}

export type SetFullScreen = {
  type: typeof actionTypes.SET_FULL_SCREEN;
  payload: boolean
}

export type GetSubscriptionStatus = {
  type: typeof actionTypes.GET_SUBSCRIPTION_STATUS;
  payload: boolean
}

export type SetSubscriptionStatus = {
  type: typeof actionTypes.SET_SUBSCRIPTION_STATUS;
  payload: string
}

export interface MainAppReducer {
  mainAppReducer: AppState,
}

export type AppActions =
  | FetchClientDataRequest
  | FetchClientDataSuccess
  | FetchClientDataError
  | setLessonPlaying
  | SetLevelSelect
  | SetUnitSelect
  | SetTempo
  | FetchTutorialData
  | FetchTutorialDataProcessing
  | FetchTutorialDataSuccess
  | AuthenticatedStartup
  | FetchUserLevelSuccess
  | SetHighestLevelCompleted
  | FetchLevelDataSuccess
  | UpdateCurrentULP
  | UpdateCurrentULPSuccess
  | UpdatePhrasesTilNextTier
  | UpdateCurrentLevelTiers
  | UpdateTiersByLevels
  | MidiInputSetup
  | SetLastShowSurvey
  | SetShownIntroduction
  | SetUserData
  | UpdateUserData
  | ResetLesson
  | SetAudioOn
  | SetRepMetronomeSound
  | SetRepDownbeatsSound
  | LoadUserState
  | SetFullScreen
  | GetSubscriptionStatus
  | SetSubscriptionStatus
  | SetPlaySessionId


export type PrerenderedGraphics = {
  staveNote: {
    id: string
  }
}

export type PrerenderedVoiceEntry = {
  Notes: {
      graphics: PrerenderedGraphics[],
      isRest: boolean,
      pitch: {
          accidental: number,
          fundementalNote: number,
          octave: number,
          halfTone: number,
          frequency: number,
      },
      noteTie: Tie | undefined,
      length: number,
      printObject: boolean
  }[]
}

export type PrerenderedPhraseData = {
  currentTimestamp: number,
  currentMeasureIndex: number,
  currentVoiceEntries: PrerenderedVoiceEntry[]
}