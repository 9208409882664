import React from 'react';
import TimeKeeper from 'Models/TimeKeeper';
import EventStream from 'Models/EventStream';
import { MIDIVal, MIDIValInput, NoteMessage,IMIDIAccess } from '@midival/core';
import { midiPitchToFrequency, useComponentWillUnmount } from 'Utils';
import { useEffect, useState, useRef } from 'react';
import * as eventAction from 'Actions/events';
import * as Tone from 'tone';
import { TONE_SAMPLES } from 'Utils/Constants';
import { TimingOffsetsConfig } from 'Models/EventStream';
import { useAWSRumContext } from './AWSRum';
import { UseStateError } from 'Utils/Errors';
import {createWorkerFactory, useWorker} from '@shopify/react-web-worker';
import immutable from 'immutable';
// import * as Scheduler from 'WebWorkers/Scheduler'
import { useSelector, useDispatch } from 'react-redux';
import { MainAppReducer } from 'Types';
import { AuthReducer } from 'Types/AuthTypes';

/// Change the input on and off events to be react callbacks
// so that a change in state playToneSound will be reflected
// Will need to add methods to change playToneSound similar to
// functions defined in Authentication.tsx

export type MidiProviderProps = {
  children?: React.ReactNode;
  location?: any
  navigate?: any,
  sampler?: any
};

export type MidiStateType = {};

export type MidiFunctions = {
  initializeMidiInput: () => void,
  addListener: (listener: Listener) => void
  removeListener: (listener: Listener) => void
  clearListeners: () => void
  resetMidiInput: (hard?:boolean) => void
}

export type Listener = {
  name: string,
  noteOn: (ev: any)=>void
  noteOff: (ev: any)=>void
}

export const MidiContext = React.createContext<(MidiStateType & MidiFunctions) | null>(null);

export const useMidiContext: () => MidiStateType & MidiFunctions = () => {
  const state = React.useContext(MidiContext);
  if (!state) {
    throw new UseStateError(useMidiContext, MidiProvider);
  }
  return {
    ...state
  };
};

export const MidiProvider: React.FC<MidiProviderProps> = ({
  children, location, navigate, sampler
}: MidiProviderProps) => {
  const { awsRum } = useAWSRumContext();

  const [playToneSound, setplayToneSound] = useState(true)

  const [midiInitialized, setMidiInitialized] = useState(false)

  // const [midiValInput, setMidiValInput] = useState<MIDIValInput>();

  const [midiAccess, setMidiAccess] = useState<IMIDIAccess>()
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const listeners = React.useRef<Map<string,Listener>>(new Map());
  const dispatch = useDispatch()
  const midiValInputs = React.useRef<MIDIValInput[]>([])
  const [successEventSent, setSuccessEventSent] = React.useState(false);
  
  useEffect(() => {
    const isApplicablePath = location.pathname == '/lesson' || 
      location.pathname == '/repertoire-play' ||
      location.pathname == '/roadmap' || 
      location.pathname == '/tutorial' ||
      location.pathname == '/settings' || 
      location.pathname == '/repertoire-play' || 
      location.pathname == '/connect-midi'
      if(!midiInitialized && isApplicablePath) {
      resetMidiInput();
    }
  }, [location.pathname, midiInitialized])
  
  useEffect(() => {
      setMidiInitialized(false)
  }, [data?.userData?.audio_on])

  useEffect(()=> {
    if(midiInitialized == true && !successEventSent) {
      dispatch(eventAction.midiConnectionSuccessEventAction())
      setSuccessEventSent(true)
    }
  }, [midiInitialized, successEventSent])


  const resetMidiInput = React.useCallback(()=> {
    console.log("resetting midi input")
    for(let input of midiValInputs.current) {
      input?.disconnect()
    }
    midiValInputs.current = []
    setMidiInitialized(false)
    setMidiAccess(undefined)
    initializeMidiInput()
      .catch((err:Error)=> {
        awsRum?.recordError(err)
        if(location.pathname === '/lesson') {
          // navigate(`/connect-midi`)
        }
      })
  }, [data?.userData?.audio_on, midiValInputs.current, location.pathname])



  const addListener = React.useCallback((listener: Listener) => {
    console.log("adding listener " + listener.name)
    if(listeners.current.has(listener.name)) {
      console.log("Listener by that name already exists - overwriting")
    }
    listeners.current.delete(listener.name);
    listeners.current.set(listener.name, listener);
  }, [listeners])

  const removeListener = React.useCallback( (listener: Listener) => {
    console.log("removing listener " + listener.name )
    listeners.current.delete(listener.name);
  }, [listeners])
  const noteOff = React.useCallback((ev: any) => {
    if (data?.userData?.audio_on) {
      sampler.triggerRelease(midiPitchToFrequency(ev.note))
    }
    for (let [key, listener] of listeners.current) {
      listener.noteOff(ev)
    }
  }, [sampler,data?.userData?.audio_on, listeners])

  const noteOn = React.useCallback((ev: any) => {
    // sometimes midi keyboards will send note-on event with 0 velocity
      // we must treat this like a not off event instead
      if (ev.velocity !== 0) {
        const maxVolume = 1.0
        // console.log("data?.userData?.audio_on is : " + data?.userData?.audio_on)
        if (data?.userData?.audio_on) {
          sampler.triggerAttack(midiPitchToFrequency(ev.note), Tone.immediate(), ev.velocity / 128 * maxVolume)
        }
        for (let [key, listener] of listeners.current) {
          listener.noteOn(ev)
        }
      } else {
        noteOff(ev) // call the note off logic becasue velocity is 0.
      }
  }, [data?.userData?.audio_on, sampler, noteOff, listeners])

  const initializeMidiInput = React.useCallback(async (hard = false) => {
    // console.log("initializing midi input")
    // console.log("midi access:")
    // console.log(midiAccess)
    let retries = 0
    let access;
    if (!midiAccess) {
      while(retries < 2) {
        try {
          access = await MIDIVal.connect()
          console.log("access found")
          console.log(access)
          setMidiAccess(access)
          if (!access || !access?.inputs || access?.inputs?.length == 0) {
            throw new Error("Failed to setup MIDI: no inputs available!"); 
          }
          break;
        } catch {
          console.log("retrying midi input")
          await new Promise((resolve) => setTimeout(resolve, 300));
          retries += 1;
        }
      }
    } else {
      access = midiAccess;
    }
    // console.log("midiValInputs")
    // console.log(midiValInputs.current)
    if (!access || !access?.inputs || access?.inputs?.length === 0) {
      if(location.pathname === '/tutorial' ||  location.pathname === '/lesson') {
        // if roadmap, it's fine for this to fail. We want people to be able to see the
        // roadmap without the midi keyboard when they first log in. On the otherhand, we want
        // to be able to play the piano anywhere in the app. So keep the initialization in case it's
        // there.
        console.log("midi access malformed or undefined")
        console.log(midiAccess)
        throw new Error("Midi initialization failed")

      }
    } else if(sampler && midiValInputs.current.length === 0) {
      // midiAccess?.inputs.forEach(input => input.)
      access?.inputs?.forEach(input => {
        
        const newMidiValInput = new MIDIValInput(input)
        newMidiValInput?.onAllNoteOn(ev => noteOn(ev))
        newMidiValInput?.onAllNoteOff(ev => noteOff(ev))
        // setMidiValInput(newMidiValInput)
        midiValInputs.current.push(newMidiValInput)
      })
      console.log("new midival inputs")
      console.log(midiValInputs.current)
      setMidiInitialized(true)
      // clearListeners()
    }
   
  }, [midiAccess, midiInitialized, midiValInputs.current, noteOff, noteOn, sampler, location, data?.userData?.audio_on])


  const clearListeners = React.useCallback(() => {
    console.log("clearing listeners")
    for (let [key] of listeners.current) {
      listeners.current.delete(key)
    }
  }, [listeners.current])

  return (
    <MidiContext.Provider 
      value={{
        initializeMidiInput,
        addListener,
        removeListener,
        clearListeners,
        resetMidiInput
      }}
    >
        {children}
    </MidiContext.Provider>
  );

}


