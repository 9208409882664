import { Box } from '@mui/material';
import RepertoireItem from './RepertoireItem';
import { useSelector } from 'react-redux'
import { RepertoireData, RepertoireReducer } from 'Types/RepertoireTypes';
import { MainAppReducer } from 'Types';
import { useRef, useState, useEffect, useCallback } from 'react';
import { DragScroll } from './DragScroll'
import SearchBar from 'Components/SearchBar';
import { some } from 'lodash';

const Browse = () => {
  const appReducerData = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const userLevelData = appReducerData.currentUserLevelData
  const highestLevelCompleted = appReducerData.highestLevelCompleted

  const repertoireReducerData = useSelector((state: RepertoireReducer) => state.repertoireReducer)
  const repertoireData = repertoireReducerData.repertoireData
  const containerRef: any = useRef(null)
  const [localRepertoireDataCopy, setLocalRepertoireDataCopy] = useState(repertoireData)
  const [localsearchText, setLocalSearchText] = useState("")


  function removeSpecialCharactersWithAccents(stringWithSpecialChars: string) {
    return stringWithSpecialChars.replace(/[^\p{L}\p{N}\s]/gu, ''); // Keeps letters, numbers, and spaces (including accented characters)
  }

  function replaceAccentedCharacters(stringWithAccents: string) {
    // Mapping of accented characters to their unaccented equivalents
    const accentsMap: {[x:string]:string} = {
      'ř': 'r',
      'á': 'a', 
      'à': 'a',
      'ä': 'a',
      'ã': 'a',
      'â': 'a',
      'ǎ': 'a',
      'ò': 'o',
      'ó': 'o', 
      'ô': 'o',
      'ö': 'o',
      'č': 'c',
      'ě': 'e',
      'é': 'e',
      'ê': 'e',
      'è': 'e',
      'ë': 'e',
      'ú': 'u',
      'ü': 'u',
      'ǔ': 'u',
      'û': 'u',
      'ň': 'n',
      'ñ': 'n',
      'ń': 'n',
      'í': 'i',
      'î': 'i',
      'ì': 'i',
      'ï': 'i',
      'ť': 't',
      'ý': 'y',
      'ŷ': 'y',
      'ÿ': 'y',
      'ź': 'z',
      'ž': 'z',
      'ż': 'z',
    };
  
    return stringWithAccents
      .split('') // Split the string into an array of characters
      .map(char => accentsMap[char] || char) // Replace accented characters or keep the original
      .join('') // Join the characters back into a string
      .replace(/[^a-zA-Z0-9\s]/g, ''); // Remove any remaining special characters
  }

  function normalizeString(unNormalString:string) {
    return removeSpecialCharactersWithAccents(replaceAccentedCharacters(unNormalString.toLocaleLowerCase()))
  }
  
  useEffect(()=>{
    console.log("localRepertoireDataCopy")
  },[localRepertoireDataCopy])

  const filterRepertoire = useCallback((searchText: string)=>{
    const normalizedSearchtext = normalizeString(searchText)
    // console.log(normalizedSearchtext)
    setLocalSearchText(searchText)
    setLocalRepertoireDataCopy(
      repertoireData.filter((rep: RepertoireData) => {
        const searchStringInTitle = normalizeString(rep.name).includes(normalizedSearchtext)
        const searchStringInComposers = some(rep.composers, (composer)=>{
          // console.log(normalizeString(composer.name))
          return normalizeString(composer.name).includes(normalizedSearchtext); 
        })
        const searchStringInArtists = some(rep.artists, (artists)=>{
          return normalizeString(artists.name).includes(normalizedSearchtext); 
        })
        console.log("searchStringInTitle || searchStringInComposers || searchStringInArtists", searchStringInTitle || searchStringInComposers || searchStringInArtists)
        return searchStringInTitle || searchStringInComposers || searchStringInArtists
      })  
    )
  },[repertoireData, localRepertoireDataCopy, setLocalRepertoireDataCopy, localsearchText,setLocalSearchText])

  let repsByLevels: any = []
  for (let i = 0; i <= highestLevelCompleted; i++) {
    repsByLevels.push([])
  }
  let futureRep:any = {}
  repertoireData.forEach((rep) => {
    if (rep.level.level_number <= highestLevelCompleted) {
      repsByLevels[rep.level.level_number-1].push(rep)
    } else {
      if (futureRep[rep.level.level_number]) {
        futureRep[rep.level.level_number].push(rep)
      } else {
        futureRep[rep.level.level_number] = [rep]
      }
    }
  })
  repsByLevels = repsByLevels.filter((rep: any) => rep.length > 0).reverse()

  return (
    <Box sx={{margin: '0px 0px', justifyContent: 'flex-start',
    height: 'calc(100vh - 60px)', overflowY: 'auto'
  }} ref={containerRef}>
      
     

    
      { repsByLevels.length > 0 &&
        <>
        <Box sx={{...recForYouStyles, justifyContent: 'space-between', display: 'flex', alignItems: 'center'}}>
          Songs At Your Level
          <div style={{ }}>
          <SearchBar onChange={filterRepertoire} sx={{ margin: 0 }}/>
          </div>
        </Box>
        {localsearchText === "" && 
        <Box sx={{margin: '0px 28px', paddingTop: '20px'}}>
          {
          repsByLevels.map((repsByLevel:any, idx:any) => {
            return <RepertoireByLevel repsByLevel={repsByLevel} level_number={repsByLevel[0].level.level_number} containerRef={containerRef}/>
          })
          }
        </Box>
        }
         {localsearchText !== "" && 
            <Box sx={{
              ...containerStyles,
              display: 'flex',
              flexWrap: "wrap",
              padding: '20px',
              justifyContent: 'flex-start',
              gap: '14px',
              overflowX:'wrap'
            }}>
            {/* <DragScroll> */}
              {localRepertoireDataCopy.map((rep:any) =>
              <RepertoireItem repertoireGroup={''} repertoireData={rep} containerRef={containerRef}/>
              )}
            {/* </DragScroll> */}
          </Box>
          }
        </>
      }

      { localsearchText === "" && Object.keys(futureRep).length > 0 &&
        <>
        <Box 
          sx = {{
            // backgroundColor: '#171F31',
            color: '#ADB1B7',
            textAlign: 'left',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: '700', 
            fontSize: '18px',
            borderBottom: '1px solid #525969',
          }}
          // sx={recForYouStyles}
        >
          Songs Above Your Level
        </Box>

        <Box sx={{margin: '0px 28px', paddingTop: '20px'}}>
          {
            Object.keys(futureRep).map((k:any, idx:any) => {
              return <RepertoireByLevel repsByLevel={futureRep[k]} level_number={k} hideTitle={true} containerRef={containerRef}/>
            })
          }
        </Box>
        </>
      }
      <Box sx={{height: '360px'}}/>
    </Box>
  )
}
export default Browse

const RepertoireByLevel = (props: any) => {
  const { 
    repsByLevel,
    level_number,
    hideTitle,
    containerRef,
  } = props

  if (!repsByLevel || repsByLevel.length == 0) {
    return (<></>)
  }
  
  return (
    <Box sx={containerStyles}>
      <Box sx={titleStyles}>Level {level_number}</Box> 
      <DragScroll>
        {repsByLevel.map((rep:any) =>
        <RepertoireItem repertoireGroup={'recent-played'} repertoireData={rep} containerRef={containerRef}/>
        )}
      </DragScroll>
    </Box>
  )
}

const containerStyles = {
  margin: '4px 0px'
}

const titleStyles = {
  textAlign: 'left',
  fontFamily: 'Lato',
  color: '#ECECEC',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '24px',
}

const recForYouStyles = {
  // had to decrease top/bottom padding here to deal with the search bar size in order to match padding of Home
  padding: '13px 28px',
  backgroundColor: '#171F31',
  color: '#ADB1B7',
  textAlign: 'left',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '18px',
}
