import React, { useRef, useEffect, useState, useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import StaffLine from '../StaffLine';
import { Box } from '@mui/material';
import Phrase from 'Models/Phrase';
import { range } from 'lodash';
import EventStream from 'Models/EventStream';
import ITimeKeeper from 'Models/ITimeKeeper';
// import CursorSelector from './CursorSelector';

type StaffProps = {
  midiStream: EventStream,
  timeKeeper: ITimeKeeper,
  phrases: Phrase[],
  topPhraseStartTimestamp: number,
  bottomPhraseStartTimestamp: number,
  scheduler: any,
  updateKey: number,
  cursorIsVisible: boolean,
  scrollPosition?: any,
  setScrollPosition?: any,
}

const Staff: React.FC<StaffProps> = ({
  midiStream,
  timeKeeper,
  phrases,
  topPhraseStartTimestamp,
  bottomPhraseStartTimestamp,
  scheduler,
  updateKey,
  cursorIsVisible,
  scrollPosition, setScrollPosition,
}) => {

  const matches = useMediaQuery('(max-width:1450px)');
  const [isPlaying, setIsPlaying] = useState(false)
  const [topPhraseCountInTimestamp, setTopPhraseCountInTimestamp] = React.useState(-1)
  const [bottomPhraseCountInTimestamp, setBottomPhraseCountInTimestamp] = React.useState(0)
  
  const duration = 1000;

  let perPhraseDivrefs = useRef(range(phrases.length).map(() => React.createRef<HTMLDivElement>()))

  useEffect(() => {
    
    perPhraseDivrefs.current.forEach((phraseRef, index) => {
      if (!perPhraseDivrefs.current[index]) {
        perPhraseDivrefs.current[index] = React.createRef<HTMLDivElement>();
      }
    })

    let aggLength = 0
    phrases.forEach((phrase, index) => {
      let numPhrases = phrase.musicXML.measures.length
      let timeSignatureNumerator = phrase.musicXML.timeSignatures[0].numerator
      let timeSignatureDenominator = phrase.musicXML.timeSignatures[0].denominator
      if (index == 0) {
        if(phrase.timesigIterator){
          midiStream.addEvents2(phrase.timesigIterator, aggLength)//numPhrases*index)
        }
      } else {
        if(phrase.iterator){
          midiStream.addEvents2(phrase.iterator, aggLength)//numPhrases*index)
        }
      }
      aggLength += numPhrases*timeSignatureNumerator/timeSignatureDenominator
    })
  }, [])

  useEffect(() => {
    // TODO unmount logic? Need to un-register from the scheduler later
    if (scheduler) {
      scheduler.addPauseCallback((countInMeasure: number) => {
        setTopPhraseCountInTimestamp(countInMeasure)
        setBottomPhraseCountInTimestamp(countInMeasure)
        setIsPlaying(false)
      })
      scheduler.addUnpauseCallback((countInTicks: number, startMeasure: number, ticksPerMeasure: number) => {
        setTopPhraseCountInTimestamp(-1)
        setBottomPhraseCountInTimestamp(-1)
        setIsPlaying(true)
      })
    }
  }, [scheduler, updateKey])

  // const defaultStyle = {
  //   transition: `opacity ${duration}ms ease-in-out`,
  //   opacity: 0,
  // }

  const defaultStyle = useMemo(() => ({
      transition: `opacity ${duration}ms ease-in-out`,
      opacity: 0,
  }), []);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Box sx={{ margin: 0, width: '90vw' }} ref={containerRef} >
      { phrases.map((phrase, index) => {
        // Figure out what these need to be!!
        const timeSignature = index === 0;
        const phraseStartTimestamp = index === 0 ? topPhraseStartTimestamp : bottomPhraseStartTimestamp;
        const countInTimestamp = index === 0 ? topPhraseCountInTimestamp : bottomPhraseCountInTimestamp;

        return (
          <PhraseComponent
            key={index}
            phrase={phrases[index]}
            index={index}
            divRef={perPhraseDivrefs.current[index]}
            matches={matches}
            timeKeeper={timeKeeper}
            isPlaying={isPlaying}
            cursorIsVisible={cursorIsVisible}
            defaultStyle={defaultStyle}
            timeSignature={timeSignature}
            phraseStartTimestamp={phraseStartTimestamp}
            countInTimestamp={countInTimestamp}
            scrollPosition={scrollPosition}
            setScrollPosition={setScrollPosition}
          />
        )
      })
      }
      <Box><i style={{fontSize: 12}}>For educational purposes only</i></Box>
      <Box sx={{height: '70px'}}/>
    </Box>
  );
}

export default Staff;


interface PhraseComponentProps {
  key: string | number;
  phrase: any;
  index: number;
  divRef: React.RefObject<HTMLDivElement>;
  matches: boolean;
  timeKeeper: any;
  isPlaying: boolean;
  cursorIsVisible: boolean;
  defaultStyle: React.CSSProperties;
  timeSignature: boolean;
  phraseStartTimestamp: number;
  countInTimestamp: number;
  scrollPosition: any;
  setScrollPosition: any;
}

const PhraseComponent: React.FC<PhraseComponentProps> = ({
  key, phrase, index, divRef, matches, timeKeeper, isPlaying, cursorIsVisible, defaultStyle, timeSignature, phraseStartTimestamp, countInTimestamp, scrollPosition, setScrollPosition
}) => {
  let staffHeight = matches ? 200 : 250
  let phraseSelect
  if (index == 0) {
    phraseSelect = phrase?.svgTimesigURL || ""
    staffHeight += 42 // same as TEMPO_SVG_HEIGHT
  } else {
    phraseSelect = phrase?.svgURL || ""
  }

  return (
    <Box key={key} sx={{ height: `${staffHeight}px`}}>
      <StaffLine
        style={{ ...defaultStyle, opacity: 1 }}
        timeSig={timeSignature}
        divRef={divRef}
        phrase={phrase}
        timeKeeper={timeKeeper}
        startTimestamp={phraseStartTimestamp}
        isPlaying={isPlaying}
        countInTimestamp={countInTimestamp}
        cursorIsVisible={cursorIsVisible}
        phraseSvgUrl={phraseSelect}
        phraseIndex={index}
        scrollPosition={scrollPosition}
        setScrollPosition={setScrollPosition}
        isRepertoireStaff={true}
      />
    </Box>
  );
}
